import { IonContent, IonPage, IonLabel, IonInput, IonCardTitle, IonCardSubtitle, IonList,
    IonImg, IonItem, IonLoading, IonButton, IonCard, IonCardContent, IonCardHeader, } from '@ionic/react';
import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router';
import './Bookings.css';
import firebaseApp, { loginUser, logoutUser } from '../components/Firebase/FirebaseApp';
import NiceLaunch from '../assets/images/NICELAUNCHICON.png';
import {toast} from '../components/Toast/Toast';
const Bookings = () => {

  // const { name } = useParams();
  const [ showLoading, setShowLoading ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const history = useHistory();
  // const fbDb = firebaseApp.firestore().collection('locations').doc(municipality).collection('ramps');

    useEffect(() => {
        setTimeout(() => {
            const user = firebaseApp.auth().currentUser;
            if(user) {
                const fbUser = firebaseApp.firestore().collection('users').doc(user.uid);
                fbUser.get().then(function(doc) {
                    if (doc.exists) {
                        const userInfo = doc.data();
                        if (userInfo.superUser) {
                            history.replace('/bookings');
                            window.location.reload(true);
                            setShowLoading(false);
                        } else {
                            console.log('Not Super');
                        }
                    } else {
                        console.log('No such user!')
                    }
                    })
                    .catch(function(error) {
                        console.log("error getting doc", error);
                    })
            }
        }, 1000)
    }, [])
  async function login(event) {
    event.preventDefault();
    setShowLoading(true);
    const res = await loginUser(email, password);
    if(res) {
        const user = firebaseApp.auth().currentUser;
        const fbUser = firebaseApp.firestore().collection('users').doc(user.uid);
        fbUser.get().then(function(doc) {
            if (doc.exists) {
                const userInfo = doc.data();
                if (userInfo.superUser) {
                    history.replace('/bookings');
                    window.location.reload(true);
                    setShowLoading(false);
                } else {
                    console.log('Not Super');
                    logoutUser();
                    setShowLoading(false);
                    toast('You do not have access to this application.');
                }
            } else {
                console.log('No such user!')
            }
            })
            .catch(function(error) {
                console.log("error getting doc", error);
                toast('There was an error.')
            })
    }

  }
  return (
    <IonPage>
      <IonContent>
        {showLoading ? (
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Please wait...'}
            duration={2000}
          />) 
          : null}
          <div style={{height:'100vh'}}>
            <IonCard mode="ios" className="loginCard vertical-center">
            <IonImg src={NiceLaunch} className="logo" />
              <IonCardHeader>
                <IonCardSubtitle>Please</IonCardSubtitle>
                <IonCardTitle>Sign in</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <form onSubmit={login}>
                    <IonList>
                        <IonItem lines="full">
                            <IonLabel position="floating">Email address</IonLabel>
                            <IonInput 
                                autofocus="true" clearInput="true" 
                                inputmode="email" value={email} 
                                type="email"
                                onIonChange={e => setEmail(e.detail.value)}></IonInput>
                        </IonItem>
                        <IonItem lines="full">
                            <IonLabel position="floating">Password</IonLabel>
                            <IonInput 
                                value={password} clearInput="true"
                                 inputmode="text" type="password"                
                                 onIonChange={e => setPassword(e.detail.value)}></IonInput>
                        </IonItem>
                        <div style={{height: 30}}/>
                        <IonButton expand="block" type="submit">Sign in</IonButton>
                    </IonList>
                </form>
              </IonCardContent>
            </IonCard>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default Bookings;
