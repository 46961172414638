import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonImg,
    IonTitle,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle
  } from '@ionic/react';
  
  import React, { useEffect } from 'react';
  import moment from 'moment';
  import { archiveOutline, archiveSharp, calendarOutline, calendarSharp, 
    heartOutline, heartSharp, addCircleOutline, addCircleSharp, 
    trashOutline, trashSharp, warningOutline, warningSharp, logOutOutline } from 'ionicons/icons';
//   import './Menu.css';

  const RampInfo = (props) => {
    // console.log(props)
    return (
        <div>
        {/*
        <IonCardHeader>
            <IonCardSubtitle>{moment(selectedDate).format('dddd, MMM Do')}</IonCardSubtitle>
        </IonCardHeader>
        */}
        <IonCardHeader>
            <IonCardSubtitle>{moment(props.date).format('dddd, MMMM Do')}</IonCardSubtitle>
            <IonCardTitle>{props.name}</IonCardTitle>
            <IonCardSubtitle className="ion-text-capitalize">{props.type} bookings</IonCardSubtitle>
        </IonCardHeader>
        </div>
    );
  };
  
  export default RampInfo;
  