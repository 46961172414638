import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonLabel, IonIcon, IonText, IonList, IonAlert, IonSkeletonText, IonCardHeader, IonCardSubtitle, IonCardTitle, IonTitle, IonToolbar, IonItem, IonSearchbar, IonItemDivider, IonModal, IonListHeader, IonButton, IonActionSheet, IonGrid, IonRow, IonCol, IonCard, IonNote, IonCardContent } from '@ionic/react';
import React, {useState, useEffect, useRef} from 'react';
// import { useParams } from 'react-router';
import { Base64 } from 'js-base64';
import moment from 'moment';
import './Bookings.css';
import firebaseApp, { logoutUser } from '../components/Firebase/FirebaseApp';
import { checkmarkOutline, checkmarkDoneOutline, closeOutline, locationOutline, timeOutline, checkmarkCircleOutline, closeCircleOutline,
         personOutline, homeOutline, mailOutline, callOutline, calendarOutline, boatOutline} from 'ionicons/icons';
const UserSearch = () => {
  const [ loading, setLoading ] = useState(false);
  const [ searchText, setSearchText ] = useState(null);
  const [ results, setResults ] = useState([]);
  const [ currentBooking, setCurrentBooking ] = useState();
  const [ showActionSheet, setShowActionSheet ] = useState(false);
  const [ showAlert, setShowAlert ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);
  const now = Date.now();
  const pageRef = useRef();
  const skeletons = [1,2,3,4,5,6,7]
  const detailModal = (book) => {
    console.log(book);
    setCurrentBooking(book);
    setShowModal(true);
  }
  const shouldCancel = (e) => {
    setShowAlert(true);
  }
  async function cancelBooking() {
    if(currentBooking !== null) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic OmxpdmVfYXBpX2tleV93eVZTUmJKSmlQbU5iSUNXSU5KQlJqbVp3bGNuWklRcQ==");
      myHeaders.append("Cookie", "laravel_session=cDzBK5CsEey1wDO6vev0aMbkSnikTzumPSbJczSi");

      var raw = JSON.stringify({"--data":{"message":""}});

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://api.timekit.io/v2/bookings/" + currentBooking.id + "/cancel", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .then(setResults(results.filter(item => item.id !== currentBooking.id)))
        .then(setShowModal(false))
        .catch(error => console.log('error', error));
      }
  }
  useEffect(() => console.log(searchText), [searchText])
  useEffect(() => {
    
    setLoading(true);
   
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic OmxpdmVfYXBpX2tleV93eVZTUmJKSmlQbU5iSUNXSU5KQlJqbVp3bGNuWklRcQ==");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
   
    fetch(`https://api.timekit.io/v2/bookings?include=attributes&search=customer.name:${searchText}*`, requestOptions)
      .then(response => response.json())
      .then(result => {   
        const bookingsSorted = result.data.sort((a,b) => (b.attributes.event.start > a.attributes.event.start) ? 1 : ((a.attributes.event.start > b.attributes.event.start) ? -1 : 0)); 
        setResults(bookingsSorted)
        console.log(bookingsSorted)
      })
      .catch(error => console.log('error', error));
    
    setLoading(false)
    console.log(`https://api.timekit.io/v2/bookings?include=attributes&search=customer.name:${searchText}`)
  
  }, [searchText])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            User Search
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              User Search
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar debounce={1000} enterkeyhint="search" value={searchText} placeholder="First then last name" onIonClear={() => setSearchText(null)} onIonChange={e => setSearchText(e.detail.value.length >= 3 ? e.detail.value : null)}></IonSearchbar>
        <IonCard>
        {searchText === null ? (
          <div>
            <IonCardHeader>
              <IonCardSubtitle>User Booking</IonCardSubtitle>
              <IonCardTitle>Search {results >= 1 ? `result for` : null}</IonCardTitle>
              <IonCardSubtitle>{results >= 1? `${searchText}` : null}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonText className="suggestion">Use the search box above with first name + last name, e.g. "Jane Smith"</IonText>
            </IonCardContent>
          </div>
          ) :
        searchText !== null && results.length > 0 ?
        <IonGrid className="ion-padding-vertical">
          <IonRow>
            <IonCol className="ion-padding">
              <IonTitle>Booking Statuses</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonIcon icon={checkmarkDoneOutline} color="medium" /> Completed
            </IonCol>
            <IonCol className="ion-text-center">
              <IonIcon icon={checkmarkOutline} color="success" /> Confirmed
            </IonCol>
            <IonCol className="ion-text-center">
              <IonIcon icon={closeOutline} color="warning" /> Cancelled
            </IonCol>
          </IonRow>
        </IonGrid>
        : null}
        {searchText !== null && results.length <= 0 ? 
          skeletons.map((skel, i) => (
            <div key={i}>
              <IonItemDivider color={'light'}>
                <IonLabel>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                </IonLabel>
              </IonItemDivider>
              <IonItem lines="none" detail>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonSkeletonText animated style={{ width: '95%' }} />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonSkeletonText animated style={{ width: '100%' }} />
                    </IonCol>
                    <IonCol>
                    <IonSkeletonText animated style={{ width: '100%' }} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              </div>
          ))
        : results !== [] && results.map(item => (
        <div key={item.id}>
        <IonItemDivider color={'light'}>
          <IonLabel>
          {moment(item.attributes.event.start).format('MMMM Do, YYYY. hh:mm a')}</IonLabel>
        </IonItemDivider>
        <IonItem lines="none" detail onClick={() => detailModal(item)}>
          <IonNote slot="start" className="status">
          <IonIcon color={item.state === 'confirmed' ? 'success' : item.state === 'cancelled' ? 'warning' : 'medium'} 
          icon={item.state === 'confirmed' ? checkmarkOutline : item.state === 'cancelled' ? closeOutline : checkmarkDoneOutline}/>
          </IonNote>
          <IonGrid>
            <IonRow>
              <IonCol>
                {item.attributes.event.description}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {item.attributes.customer.name}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        </div>
        ) 
        )}
        </IonCard>
        <IonModal
          animated
          mode="ios"
          isOpen={showModal}
          cssClass='my-custom-class'
          swipeToClose={true}
          presentingElement={pageRef.current}
          onDidDismiss={() => setShowModal(false)}>
            {currentBooking !== undefined ?
            <div>
              <IonHeader>
                <IonToolbar>
                  <IonTitle 
                  className="ion-text-capitalize" 
                  color={currentBooking.state === 'confirmed' ? 'success' : currentBooking.state === 'cancelled' ? 'warning' : 'medium'}>
                  {currentBooking.state}</IonTitle>
                  <IonButtons slot="end">
                    {/**<IonButton onClick={() => setShowActionSheet(true)}>Actions</IonButton>**/}
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonListHeader>
                <IonLabel>Launch Details</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonIcon slot="start" icon={locationOutline} />               
                {currentBooking.attributes.event.description}
              </IonItem>
              <IonItem>
                <IonIcon slot="start" icon={calendarOutline} />               
                {moment(currentBooking.attributes.event.start).format('dddd, MMM Do YYYY')}, {moment(currentBooking.attributes.event.start).format('hh:mm a')}
              </IonItem>
              <IonListHeader>
                <IonLabel>Boater Information</IonLabel>
              </IonListHeader>
              <IonList>
                <IonItem>
                  <IonIcon slot="start" icon={personOutline} />               
                  {currentBooking.attributes.customer.name}
                </IonItem>
                {currentBooking.attributes.customer.address_1 ?
                <IonItem>
                  <IonIcon slot="start" icon={homeOutline} />
                  {currentBooking.attributes.customer.address_1}
                </IonItem>
                : null}
                <IonItem>
                  <IonIcon slot="start" icon={mailOutline} />
                  {currentBooking.attributes.customer.email}
                </IonItem>
                <IonItem>
                  <IonIcon slot="start" icon={callOutline} />
                  {currentBooking.attributes.customer.phone}
                </IonItem>
                <IonItem>
                  <IonIcon slot="start" icon={boatOutline} />
                  {currentBooking.attributes.customer.license_plate}
                </IonItem>
              </IonList>
            </div>
            : null}
            
            <IonButton 
              color="primary" fill="clear" onClick={() => setShowModal(false)} expand="block">
              Close
            </IonButton>
            <IonActionSheet
              isOpen={showActionSheet}
              onDidDismiss={() => setShowActionSheet(false)}
              cssClass='my-custom-class'
              buttons={currentBooking && currentBooking.state === 'cancelled' || 
                currentBooking && currentBooking.state === 'completed' ? 
                [{
                  text: 'Already Cancelled',
                  disabled: true,
                  role: 'cancel',
                  // icon: share,
                  handler: () => {
                    console.log('Already canned clicked');
                  }
                }, 
                {
                  text: 'Close',
                  role: 'cancel',
                  handler: () => {
                    console.log('Cancel clicked');
                  }
                }]
                :
                [{ 
                  text: 'Cancel Booking',
                  role: 'destructive',
                  // icon: trash,
                  handler: () => {
                    console.log('Delete clicked');
                    shouldCancel();
                  }
                }, 
                {
                  text: 'Mark As Missed',
                  // icon: share,
                  handler: () => {
                    console.log('Share clicked');
                  }
                }, 
                {
                  text: 'Close',
                  role: 'cancel',
                  handler: () => {
                    console.log('Cancel clicked');
                  }
                }
              ]}
            >
            </IonActionSheet>
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              cssClass='my-custom-class'
              header={'Alert'}
              subHeader={'Please confirm'}
              message={'Do you want to cancel this booking?'}
              buttons={[{
                text: 'Never mind',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Cancelled cancel');
                  setShowAlert(false);
                }
              },
              {
                text: 'Yes, cancel booking',
                handler: () => {
                  console.log('Confirm booking cancellation');
                  cancelBooking();
                }
              }]}
            />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default UserSearch;
