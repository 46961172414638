import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonLabel, IonIcon, 
        IonText, IonChip, IonRow, IonCol, IonItemDivider, IonModal, IonTitle, IonToolbar, 
        IonItem, IonSelect, IonSelectOption, IonLoading, IonDatetime, IonButton, IonNote, 
        IonGrid, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle, IonList, 
        IonListHeader, IonActionSheet, IonSpinner, IonAlert, IonPopover, IonSegment, IonSegmentButton, IonCheckbox } from '@ionic/react';
import React, {useState, useEffect, useRef} from 'react';
import RampInfo from '../components/RampInfo/RampInfo';
import { Base64 } from 'js-base64';
import moment from 'moment';
import './Bookings.css';
import firebaseApp from '../components/Firebase/FirebaseApp';
import { boatOutline, calendarOutline, callOutline, personOutline, homeOutline, mailOutline,
        timeOutline, locationOutline, filterOutline, checkmarkCircleOutline, closeCircleOutline, swapVerticalOutline } from 'ionicons/icons';
import { WiHorizonAlt, WiSunrise, WiSunset } from 'weather-icons-react';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;
// import QRCode from 'qrcode.react';
// import QrReader from 'react-qr-reader'

const Bookings = () => {
  const [ user, setUser ] = useState({});
  const [ count, setCount ] = useState(0);
  const [ showLoading, setShowLoading ] = useState(true);
  const [ spinner, setSpinner ] = useState(false);
  const [ showModal, setShowModal ] = useState(false);
  const [ municipality ] = useState('Innisfil');
  const [ bookings, setBookings ] = useState([]);
  const [ morningBookings, setMorningBookings ] = useState(null);
  const [ afternoonBookings, setAfternoonBookings ] = useState(null);
  const [ eveningBookings, setEveningBookings ] = useState(null); 
  const [ bookingState, setBookingState ] = useState(window.localStorage.getItem('filter') || 'confirmed');
  const [ selectedDate, setSelectDate ] = useState(moment(Date.now()).format('YYYY-MM-DD'));
  const [ startTime, setStartTime ] = useState(selectedDate+'T00:00:01-0400');
  const [ endTime, setEndTime ] = useState(selectedDate+'T24:00:00-0400');
  const [ locations, setLocations ] = useState([]);
  const [ resourceId, setResourceId ] = useState(window.localStorage.getItem('lastResource'));
  const [ selectedLocation, setSelectedLocation ] = useState({});
  const [ currentBooking, setCurrentBooking ] = useState();
  const [ showCancelAlert, setShowCancelAlert ] = useState(false);
  const [ showConfirmationAlert, setShowConfirmationAlert ] = useState(false);
  const [ showMenu, setShowMenu ] = useState({open: false, event: null});
  const [ bookingsSegment, setBookingsSegment ] = useState('bookings');
  const [ updatingBooking, setUpdatingBooking ] = useState(false);
  const [ selectedFBUser, setSelectedFBUser ] = useState();
  const [ currentBookingId, setCurrentBookingId ] = useState(null);
  const fbDb = firebaseApp.firestore().collection('locations').doc(municipality).collection('ramps');
  const url = bookingState === 'confirmed' || bookingState === 'cancelled' ? 
    `https://api.timekit.io/v2/bookings?search=resource.id:${resourceId};state:${bookingState}&include=attributes,event,customers&start=${startTime}&end=${endTime}`
  : `https://api.timekit.io/v2/bookings?search=resource.id:${resourceId}&include=attributes,event,customers&start=${startTime}&end=${endTime}`;
  const allUrl = bookingState === 'confirmed' || bookingState === 'cancelled' ? 
    `https://api.timekit.io/v2/bookings?search=state:${bookingState}&include=resource,attributes,event,customers&start=${startTime}&end=${endTime}`
  : `https://api.timekit.io/v2/bookings?include=resource,attributes,event,customers&start=${startTime}&end=${endTime}`;
  const tkUser = Base64.encode(':live_api_key_wyVSRbJJiPmNbICWINJBRjmZwlcnZIQq');
  const now = Date.now();
  const pageRef = useRef();
  const currentUser = firebaseApp.auth().currentUser.uid;
  
  
  const getLocationById = () => {
    fbDb.get().then((querySnapshot) => {
      let locationsList = querySnapshot.docs.map((doc) => {
          return doc.data()
      });
      setLocations(locationsList);
    });
  }
  
  const detailModal = (book) => {
    console.log(book);
    setCurrentBooking(book);
    firebaseApp.firestore().collection("users").where("email", "==", book.customers[0].email)
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            const id = doc.id
            console.log(id, " => ", doc.data());
            setSelectedFBUser(id);
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
    setShowModal(true);
  }

  function changeState(book) {
    // console.log(book);
    setCurrentBooking(book);
    firebaseApp.firestore().collection("users").where("email", "==", book.customers[0].email)
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            const id = doc.id
            // console.log(id, " => ", doc.data());
            setSelectedFBUser(doc.id);
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
    setShowConfirmationAlert(true);
  }
  function shouldCancel() {
    setShowCancelAlert(true);
  }
  async function cancelBooking() {
    if(currentBooking !== null) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Basic OmxpdmVfYXBpX2tleV93eVZTUmJKSmlQbU5iSUNXSU5KQlJqbVp3bGNuWklRcQ==");
      myHeaders.append("Cookie", "laravel_session=cDzBK5CsEey1wDO6vev0aMbkSnikTzumPSbJczSi");

      var raw = JSON.stringify({"--data":{"message":""}});

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://api.timekit.io/v2/bookings/" + currentBooking.id + "/cancel", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .then(setBookings(bookings.filter(item => item.id !== currentBooking.id)))
        .then(setShowModal(false))
        .catch(error => console.log('error', error));
      }
      firebaseApp.firestore().collection("users").doc(selectedFBUser).collection("bookings").doc(currentBooking.id).delete().then(function() {
        console.log("Booking successfully deleted!");
        setCount(count - 1);
      })
  }

  function markMissed() {
    // console.log(selectedFBUser);
    // console.log(currentBooking);
    setUpdatingBooking(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Basic OmxpdmVfYXBpX2tleV93eVZTUmJKSmlQbU5iSUNXSU5KQlJqbVp3bGNuWklRcQ==");
    myHeaders.append("Cookie", "laravel_session=ZtV7eDwFt5aOEMF9XU1tnXwK0D8YZc3bTmBmG4eu");

    var raw = JSON.stringify(
      currentBooking.attributes.meta.missed === "false" || 
      currentBooking.attributes.meta.missed === undefined ? {"meta":{"complete":"false","missed":"true"}} 
      : {"meta":{"complete":"false","missed":"false"}});

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://api.timekit.io/v2/bookings/${currentBooking.id}`, requestOptions)
      .then(response => response.text())
      .then(() => setCount(count + 1))
      .then(() => setUpdatingBooking(false))
      // .then(result => console.log(result))
      .catch(error => console.log('error', error))

    if(currentBooking.attributes.meta.missed === "false") {
      const user = selectedFBUser;
      firebaseApp.firestore().collection("users").doc(user).collection("missedBookings")
      .doc(currentBooking.id).set({
          missed: true,
          start: currentBooking.attributes.event.start,
          end: currentBooking.attributes.event.end,
          id: currentBooking.id,
          description: currentBooking.attributes.event.description,
          state: currentBooking.state
      })
      .then(function() {
          console.log("Booking marked missed!");
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
      });
    } 
    if(currentBooking.attributes.meta.missed === "true") {
      const user = selectedFBUser;
      firebaseApp.firestore().collection("users").doc(user).collection("missedBookings")
      .doc(currentBooking.id).delete().then(function() {
        console.log("Document successfully deleted!");
      }).catch(function(error) {
          console.error("Error removing document: ", error);
      });
    } 
  }

  function markDone() {
    // console.log(book);
    setUpdatingBooking(true);
    setCurrentBookingId(currentBooking.id);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Basic OmxpdmVfYXBpX2tleV93eVZTUmJKSmlQbU5iSUNXSU5KQlJqbVp3bGNuWklRcQ==");
    myHeaders.append("Cookie", "laravel_session=ZtV7eDwFt5aOEMF9XU1tnXwK0D8YZc3bTmBmG4eu");

    var raw = JSON.stringify(
      currentBooking.attributes.meta.complete === "false" || 
      currentBooking.attributes.meta.complete === undefined ? {"meta":{"complete":"true", "missed":"false"}} 
      : {"meta":{"complete":"false","missed":"false"}});

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`https://api.timekit.io/v2/bookings/${currentBooking.id}`, requestOptions)
      .then(response => response.text())
      .then(() => setCount(count + 1))
      .then(() => setUpdatingBooking(false))
      // .then(result => console.log(result))
      .catch(error => console.log('error', error))

    firebaseApp.firestore().collection("users").where("email", "==", currentBooking.customers[0].email)
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            setSelectedFBUser(doc.id);
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
  }

  function resourceSetter(e) {
    setResourceId(e.detail.value);
    // console.log(e.detail.value);
    window.localStorage.setItem('lastResource', e.target.value);
  }

  function bookState(e) {
    setBookingState(e);
    window.localStorage.setItem('filter', e)
  }

  useEffect(() => {
    
    if(selectedFBUser !== null && currentBookingId !== null) {
      firebaseApp.firestore().collection("users").doc(selectedFBUser).collection("bookings").doc(currentBookingId)
      .update({
          complete: true
      })
      .then(function() {
          console.log("Booking successfully updated!");
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
      });
    }
    
  }, [selectedFBUser]) 

  useEffect(() => {
    // console.log(resourceId);
    if(resourceId !== 'allIBP') {
      fbDb.where("resourceId", "==", resourceId).get().then((querySnapshot) => {
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          setSelectedLocation(doc.data());
        });
      });
    }
  }, [resourceId])

  useEffect(() => {
    setShowMenu({open: false, event: null})
  }, [bookingState])

  useEffect(() => {
    setSpinner(true);
    setTimeout(() => {
      setSpinner(false)
    }, 1000);
  }, [resourceId])

  useEffect(() => {
    getLocationById();
    // console.log(currentUser);
    firebaseApp.firestore().collection("users").doc(currentUser)
    .get()
    .then(function(doc) {
      if (doc.exists) {
          const data = doc.data();
          // console.log(doc.data())
          setUser(data);
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
      }).catch(function(error) {
          console.log("Error getting document:", error);
      });
  }, [])

  useEffect(() => {
    console.log(currentBooking)
  }, [currentBooking])

  useEffect(() => {
    setStartTime(selectedDate+'T00:00:01-0400');
    setEndTime(selectedDate+'T24:00:00-0400');
  }, [selectedDate])

  useEffect(() => {
    // console.log(bookings.length)
    setMorningBookings(bookings.filter(item => moment(item.attributes.event.start).format('HH') < 12));
    setAfternoonBookings(bookings.filter(item => moment(item.attributes.event.start).format('HH') >= 12 && moment(item.attributes.event.start).format('HH') < 17));
    setEveningBookings(bookings.filter(item => moment(item.attributes.event.start).format('HH') >= 17 ));
  }, [bookings])
  
  useEffect(function effectFunction() {
    if(resourceId !== null && resourceId !== 'allIBP') {
      async function getBookings() {
          const response = await fetch(url, {
            headers: {
              Authorization: `Basic ${tkUser}`,
              "Content-Type": "application/json"
            }
          });
          const json = await response.json();
          // console.log(json.data)
          const bookingsSorted = json.data.sort((a,b) => (a.attributes.event.start > b.attributes.event.start) ? 1 : ((b.attributes.event.start > a.attributes.event.start) ? -1 : 0)); 
          setBookings(bookingsSorted);
      }
      getBookings();
    } else if(resourceId !== null && resourceId === 'allIBP') {
      async function getBookings() {
        const response = await fetch(allUrl, {
          headers: {
            Authorization: `Basic ${tkUser}`,
            "Content-Type": "application/json"
          }
        });
        const json = await response.json();
        const allBookings = json.data.filter(item => item.resource.name === "IBP Ramp A" || item.resource.name === "IBP Ramp B")
        // console.log(json.data)
        const bookingsSorted = allBookings.sort((a,b) => (a.attributes.event.start > b.attributes.event.start) ? 1 : ((b.attributes.event.start > a.attributes.event.start) ? -1 : 0)); 
        setBookings(bookingsSorted);
      }
      getBookings();
    }
    setUpdatingBooking(false);
    // console.log(moment(now).format('MMMM Do YYYY, h:mm:ss a'));
  }, [startTime, resourceId, bookingState, count]);
  
  useState(() => {
    console.log('bookings');
  }, [bookings, resourceId])
  return (
    <IonPage ref={pageRef}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            Bookings
          </IonTitle>
          <IonButtons slot="end">
            <IonButton color="secondary" onClick={(e) => setShowMenu({open: true, event: e.nativeEvent})}>
              <IonIcon slot="icon-only" icon={filterOutline}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonPopover
        animated
        showBackdrop={true}
        backdropDismiss
        event={showMenu.event}
        cssClass="ion-padding"
        isOpen={showMenu.open}
        onDidDismiss={e => setShowMenu({open: false, event: null})}
      >
        <IonList>
          <IonListHeader>Filter Bookings</IonListHeader>
          <div style={{height: 10}}/>
          <IonItem onClick={() => bookState('confirmed')} lines="none" className="menuItem"><IonIcon icon={boatOutline} color="success" slot="start" /> Confirmed Only</IonItem>
          <IonItem onClick={() => bookState('cancelled')} lines="none" className="menuItem"><IonIcon icon={boatOutline} color="warning" slot="start" /> Cancelled Only</IonItem>
          <IonItem onClick={() => bookState('all')} lines="none" className="menuItem"><IonIcon icon={boatOutline} color="medium" slot="start" /> All</IonItem>
          <div style={{height: 10}}/>
        </IonList>
      </IonPopover>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Bookings
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="7" sizeMd="6">
              <IonChip className="ion-padding" color="primary">
                <IonSelect mode="ios" className="rampSelect" value={resourceId} placeholder="Select a ramp" onIonChange={e => resourceSetter(e)}>
                  <IonSelectOption className="rampOption" value={'allIBP'}>IBP All</IonSelectOption>
                  {locations.map((ramp) => {
                    return <IonSelectOption disabled={ramp.status === 'closed' ? true : false} className="rampOption" key={ramp.resourceId} value={ramp.resourceId}>{ramp.name}</IonSelectOption>
                  })}
                </IonSelect>
              </IonChip>
            </IonCol>
            <IonCol sizeXs="5" offsetMd="4" sizeMd="2" className="ion-padding-horizontal">
              <IonChip className="ion-padding" color="primary">
                <IonIcon icon={calendarOutline} className="calIcon" /><IonDatetime className="ion-no-padding dT" mode="ios" displayFormat={'MMM DD'} pickerFormat="MMMM/DD/YYYY" min={moment(Date.now()).format('YYYY-MM-DD')} max={moment((Date.now() + 12096e5)).format('YYYY-MM-DD')} value={selectedDate} 
                onIonChange={e => setSelectDate(moment(e.detail.value).format('YYYY-MM-DD'))}></IonDatetime>
              </IonChip>
            </IonCol>
          </IonRow>
        </IonGrid>
        {showLoading ? (
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Please wait...'}
            duration={1000}
          />) 
          :
          <div>
          {resourceId === null ?
            <IonCard mode="ios">
              <IonCardHeader>
                <IonCardSubtitle>Get started</IonCardSubtitle>
                <IonCardTitle>
                  <IonSelect className="ion-padding" mode="ios" value={resourceId} placeholder="Select a ramp" onIonChange={e => resourceSetter(e)}>
                    {locations.map((ramp) => {
                      return <IonSelectOption disabled={ramp.status === 'closed' ? true : false} className="rampOption" key={ramp.name} value={ramp.resourceId}>{ramp.name}</IonSelectOption>
                    })}
                  </IonSelect>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
            : bookings !== undefined && bookings.length > 0 ? 
            <IonCard className="bookingsCard">
              <RampInfo
                date={selectedDate}
                name={resourceId === 'allIBP' ? 'IBP Ramps A & B' : selectedLocation ? selectedLocation.shortName : null}
                type={bookingState} 
              />
              <div className="ion-padding-horizontal">
                <IonSegment color="primary" value={bookingsSegment} onIonChange={e => setBookingsSegment(e.detail.value)}>
                  <IonSegmentButton value="bookings">
                    <IonLabel>Bookings</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="stats">
                    <IonLabel>Stats</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              { bookingsSegment === 'bookings' ?
              <IonCardContent className="ion-no-padding bookingsCardContent">
                {bookings.map((book, i) => (
                <div key={i}>
                  <IonItemDivider color={
                    moment(book.attributes.event.start).format() < moment(now).format() ? 'light' : 
                    moment(book.attributes.event.start).format() > moment(now).format() && book.state === 'confirmed' ? 'success' :
                    moment(book.attributes.event.start).format() > moment(now).format() && book.state === 'cancelled' ? 'warning' : 
                    'light'
                    }>
                    <IonLabel>{moment(book.attributes.event.start).format('hh:mma')}</IonLabel>
                    {/**<IonLabel>{resourceId === 'allIBP' ? book.resource.name + ' - ' : null}{moment(book.attributes.event.start).format('hh:mma')}</IonLabel>**/}
                  </IonItemDivider>
                  
                  <IonItem className="taller" lines="none">
                    {book.state !== 'cancelled' ?
                    <IonNote slot="start">
                      {updatingBooking ?
                        <IonSpinner name="crescent" className="checkSpin" />
                        : <IonCheckbox color={book.attributes.meta.missed === "true" ? 'danger' : 'success'} checked={book.attributes.meta.complete === "true" || book.attributes.meta.missed === "true" ? true : false} onClick={() => changeState(book)} />
                      }
                    </IonNote>
                    : null}
                    <IonLabel onClick={() => detailModal(book)} className="userName">{book.customers[0].name}</IonLabel>
                    <IonNote onClick={() => detailModal(book)} slot="end">
                      <IonChip className="license" outline="true" color="primary">
                        <IonIcon icon={boatOutline} color="primary" />
                        <IonLabel className="ion-text-uppercase">{book.customers[0].license_plate}</IonLabel>
                      </IonChip>
                    </IonNote>
                  </IonItem>
                </div>
                ))}
              </IonCardContent>
              : 
              <IonCardContent>
                <IonGrid>
                  <IonCardSubtitle>{bookingState === 'all' || bookingState === 'cancelled' ? null : 'Remaining'} {bookingState === 'all' ? 'All' : bookingState} bookings by time of day (Total: {bookings.length})</IonCardSubtitle>
                    <IonRow>
                      <IonCol className="ion-text-center">
                        <IonText className="bold">Morning</IonText>
                      </IonCol>
                      <IonCol className="ion-text-center">
                        <IonText className="bold">Afternoon</IonText>
                      </IonCol>
                      <IonCol className="ion-text-center">
                        <IonText className="bold">Evening</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className="ion-text-center">
                        <WiSunrise size={36} color='#000' />
                        <br/>
                        <IonText className="bold">{morningBookings.length}</IonText>
                      </IonCol>
                      <IonCol className="ion-text-center">
                        <WiHorizonAlt size={36} color='#000' />
                        <br/>
                        <IonText className="bold">{afternoonBookings.length}</IonText>
                      </IonCol>
                      <IonCol className="ion-text-center">
                        <WiSunset size={36} color='#000' />
                        <br/>
                        <IonText className="bold">{eveningBookings.length}</IonText>
                      </IonCol>
                    </IonRow>
                </IonGrid>
                
              </IonCardContent>
              }
            </IonCard>
          
          : bookings !== undefined && bookings.length < 1 ? (
            <IonCard mode="ios">
            <RampInfo
              date={selectedDate}
              name={selectedLocation ? selectedLocation.shortName : 'Launch Ramp'}
              type={bookingState} 
            />
              <IonCardContent>
              { spinner ?
                <IonText>Looking for bookings...</IonText>
                : <IonText>No {bookingState === 'confirmed' ? 'confirmed' : bookingState === 'cancelled' ? 'cancelled' : ''} bookings for this ramp & date.</IonText>
              }
              </IonCardContent>
            </IonCard>
          )
          : 
          <IonCard mode="ios">
            <IonCardHeader>
              <IonCardSubtitle>Checking for bookings <IonSpinner name="crescent" /></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonText>Please wait while we look for bookings.</IonText>
            </IonCardContent>
          </IonCard>
          }
          </div>
        }
        <IonModal
          animated
          mode="ios"
          isOpen={showModal}
          cssClass='bookingsModal'
          swipeToClose={true}
          presentingElement={pageRef.current}
          // presentingElement={pageRef.bookings}
          onDidDismiss={() => setShowModal(false)}>
            {currentBooking !== undefined ?
            <div>
              <IonHeader translucent="true">
                <IonToolbar>
                  <IonTitle className="ion-text-capitalize ion-text-center">
                    Booking Details
                  </IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonCardContent className="bookingInfo">
                <IonItem>
                  <IonIcon slot="start" icon={locationOutline} />               
                  {currentBooking.attributes.event.description}
                </IonItem>
                <IonItem>
                  <IonIcon slot="start" icon={calendarOutline} />               
                  {moment(currentBooking.attributes.event.start).format('ddd. MMM Do')}, {moment(currentBooking.attributes.event.start).format('hh:mm a')}
                </IonItem>
                <IonItem className="ion-text-uppercase">
                  <IonIcon slot="start" icon={boatOutline} />
                  {currentBooking.customers[0].license_plate}{currentBooking.customers[0].boat_size !== "" ? ", " +currentBooking.customers[0].boat_size + 'ft' : null}
                </IonItem>
                <IonListHeader>
                  <IonLabel>Boater Information</IonLabel>
                </IonListHeader>
                <IonList>
                  <IonItem className="ion-text-capitalize">
                    <IonIcon slot="start" icon={personOutline} />               
                    {currentBooking.customers[0].name}
                  </IonItem>
                  {currentBooking.customers[0].address_1 ?
                  <IonItem className="ion-text-capitalize">
                    <IonIcon slot="start" icon={homeOutline} />
                    {currentBooking.customers[0].address_1}
                  </IonItem>
                  : null}
                  <IonItem>
                    <IonIcon slot="start" icon={mailOutline} />
                    {currentBooking.customers[0].email}
                  </IonItem>
                  <IonItem>
                    <IonIcon slot="start" icon={callOutline} />
                    {currentBooking.customers[0].phone}
                  </IonItem>
                </IonList>
              </IonCardContent>
            </div>
            : null}
            
            <IonButton 
              color="primary" fill="clear" onClick={() => setShowModal(false)} expand="block">
              Close
            </IonButton>
        </IonModal>
        <IonAlert
          isOpen={showCancelAlert}
          onDidDismiss={() => setShowCancelAlert(false)}
          cssClass='my-custom-class'
          header={'Alert'}
          subHeader={'Please confirm'}
          message={'Do you want to cancel this booking?'}
          buttons={[{
            text: 'Never mind',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Cancelled cancel');
              setShowCancelAlert(false);
            }
          },
          {
            text: 'Yes, cancel booking',
            handler: () => {
              console.log('Confirm booking cancellation');
              cancelBooking();
            }
          }]}
        />
        <IonAlert
          isOpen={showConfirmationAlert}
          onDidDismiss={() => setShowConfirmationAlert(false)}
          cssClass='my-custom-class'
          header={'Update this booking?'}
          message={'You can mark as "Complete", "Missed", "Cancelled", or choose "Never Mind" to go back'}
          buttons={[
            {
              text: currentBooking !== undefined && currentBooking.attributes.meta.complete === "false" || currentBooking !== undefined && currentBooking.attributes.meta.complete === undefined ? 'Mark Complete' : 'Mark as Not Complete',
              handler: () => {
                console.log('Update Complete');
                markDone();
              }
            },
            {
              text: currentBooking !== undefined && currentBooking.attributes.meta.missed === "false" || currentBooking !== undefined && currentBooking.attributes.meta.missed === undefined ? 'Mark Missed' : 'Mark as Not Missed',
              handler: () => {
                // console.log('Update Missed');
                markMissed();
              }
            },
            {
              text: 'Cancel Booking',
              handler: () => {
                console.log('Cancel Booking');
                shouldCancel();
              }
            },
            {
              text: 'Never Mind',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('Cancelled cancel');
                setShowCancelAlert(false);
              }
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Bookings;
