import Menu from './components/Menu/Menu';
import Bookings from './pages/Bookings';
import UserSearch from './pages/UserSearch';
import Login from './pages/Login';
import React, { useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import firebaseApp from './components/Firebase/FirebaseApp';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const App = () => {
  const [ showLoading, setShowLoading ] = useState(true);
  const user = firebaseApp.auth().currentUser;
  useEffect(() => {
    console.log('user');
  }, [user])
  return (
    <IonApp>
      {showLoading ? (
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Please wait...'}
            duration={1000}
          />)
      : null}
      <div>
      {user !== null ? 
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/bookings" component={Bookings} exact />
            <Route path="/search" component={UserSearch} exact />
            <Redirect from="/" to="/bookings" exact />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
      :
      <IonReactRouter>
        <IonRouterOutlet id="main">
          <Route path="/login" component={Login} exact />
          <Redirect from="/" to="/login" exact />
        </IonRouterOutlet>
      </IonReactRouter>
      }
      </div>
    </IonApp>
  );
};

export default App;
