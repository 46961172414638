import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonImg,
} from '@ionic/react';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { searchOutline, searchSharp, calendarOutline, calendarSharp, heartOutline, heartSharp, addCircleOutline, addCircleSharp, trashOutline, trashSharp, warningOutline, warningSharp, logOutOutline } from 'ionicons/icons';
import './Menu.css';
import firebaseApp, { logoutUser } from '../Firebase/FirebaseApp';
import { useAuthState } from 'react-firebase-hooks/auth';
import InnisfilInline from '../../assets/images/InnisfilInline.png';
const appPages = [
  {
    title: 'Bookings',
    url: '/Bookings',
    iosIcon: calendarOutline,
    mdIcon: calendarSharp
  },
  {
    title: 'User Search',
    url: '/search',
    iosIcon: searchOutline,
    mdIcon: searchSharp
  },
  /**{
    title: 'Favorites',
    url: '/Favorites',
    iosIcon: heartOutline,
    mdIcon: heartSharp
  },
  {
    title: 'Archived',
    url: '/Archived',
    iosIcon: archiveOutline,
    mdIcon: archiveSharp
  },
  {
    title: 'Trash',
    url: '/Trash',
    iosIcon: trashOutline,
    mdIcon: trashSharp
  },
  {
    title: 'Spam',
    url: '/Spam',
    iosIcon: warningOutline,
    mdIcon: warningSharp
  }**/
];

const Menu = (props) => {
  const [user, loading, error] = useAuthState(firebaseApp.auth());
  const history = useHistory();
  const location = useLocation();
  function logout() {
    logoutUser();
    history.replace('/login');
    window.location.reload(true);
  }
  
  useEffect(() => {
    console.log('user')
  }, [user])
  
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            <IonImg className="menuLogo" src={InnisfilInline} />
          </IonListHeader>
          <div style={{height: 50}}/>
          <IonListHeader>
            <IonLabel>Bookings & Users</IonLabel>
          </IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <div style={{height: 50}}/>
          <IonListHeader>
            <IonLabel>{user ? user.email : null}</IonLabel>
          </IonListHeader>
          
          <IonItem lines="none" onClick={logout} className="logoutBtn">
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>Log Out</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
