import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import { toast } from '../Toast/Toast';

// const settings = {timestampsInSnapshots: true};
const config = {
    apiKey: "AIzaSyAFheQFI8JMSszO6TIlS4vV2BgKaqgQkUw",
    authDomain: "nice-launch.firebaseapp.com",
    databaseURL: "https://nice-launch.firebaseio.com",
    projectId: "nice-launch",
    storageBucket: "nice-launch.appspot.com",
    messagingSenderId: "634783463268",
    appId: "1:634783463268:web:50bf8b6ba6dd9de81dac71",
    measurementId: "G-XDWN3F07QQ"
  };

const firebaseApp = firebase.initializeApp(config);

export function logoutUser() {
  return firebase.auth().signOut()
}

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        resolve(user)
      } else {
        resolve(null)
      }
      unsubscribe()
    })
  })
}

export async function loginUser(email, password) {
  try {
    const res = await firebase.auth().signInWithEmailAndPassword(email, password)
    // console.log(res)
    return true
  } catch(error) {
    toast(error.message, 4000)
    return false
  }
}

export async function registerUser(email, password) {
  try {
    const res = await firebase.auth().createUserWithEmailAndPassword(email, password)
    // console.log(res)
    return true
  } catch(error) {
    toast(error.message, 4000)
    return false
  }
}
firebase.analytics();
firebase.auth()
export default firebaseApp;